.panels-list li {
  display: list-item;
}

.panels-list__item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.panels-list__item:last-child {
  margin-bottom: 0;
}

.panels-list__label {
  margin-right: 10px;
  color: #ffffff;
}

.panels-list__btn {
  padding: 0 10px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0 0 4px #ffffff;
  border-radius: 10px;
  font-size: 14px;
  line-height: 30px;
  border: 0;
  color: var(--buttonColor);
  cursor: pointer;
  font-weight: 800;
  margin-left: 10px;
}

.sortable-item-1 {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 45px;
  gap: 15px;
}

.step4WithPrice .first-silder {
  margin-top: 10px;
  text-align: center;
}

.sortable-item-1__item {
  height: 200px;
  background: #ffffff;
  padding: 35px 5px;
  text-align: center;
  font-size: 12px;
  border: 3px solid #b8b8b8;
  position: relative;
  line-height: 105%;
  cursor: pointer;
  font-weight: 600;
}

.patishon-container-scroll .sortable-item-1__item {
  border-right: 3px solid #b8b8b8;
  border-left: 3px solid #b8b8b8;
  border-bottom: none;
  border-top: none;
}

.patishon-container-scroll .sortable-item-1__item:first-child {
  border-left: none;
}

.patishon-container-scroll .sortable-item-1__item:last-child {
  border-right: none;
}

.remove-item-icon {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 18px;
  line-height: 100%;
  cursor: pointer;
}

.sortable-item-1__item:hover .remove-item-icon {
  display: block;
}

.remove-item-icon:hover {
  scale: 1.6;
}

.patishon-container-scroll {
  display: flex !important;
  border: 6px solid #747474;
  margin-bottom: 20px;
  min-height: 212px;
  transition: all .3s ease;
}

.patishon-container-scroll--active {
  background: rgba(33, 40, 50, 0.7);
  transition: all .3s ease;
  border: 6px dashed #747474;
}

.width-label {
  color: white;
  display: block;
  margin-bottom: 5px;
  font-size: 17px;
}

.width-label:first-child {
  margin-bottom: 10px;
}

.button-container {
  text-align: center;
  background: #212832;
  padding: 10px;
}

.top-decor-line {
  position: relative;
  height: 3px;
  background: #7cb305;
  margin-bottom: 10px;
}

.top-decor-line__text {
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
}

.reight-decor-line {
  position: absolute;
  right: -10px;
  top: 0;
  height: calc(200px + 12px);
  background: #7cb305;
  width: 3px;
}

.reight-decor-line__text {
  color: #ffffff;
  text-align: center;
  writing-mode: vertical-rl;
  position: absolute;
  top: 50%;
  height: 100%;
  bottom: 0;
  transform: translateY(-50%);
  left: 22px;
  width: 100%;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step4 .dimensions-data {
  margin-bottom: 10px !important;
}

.step4 .wrapper_frames img {
  max-width: 100px;
}

.step4 .wrapper_frames.active img {
  border-width: 5px !important;
}

.step4 .toggle_slider {
  max-width: 450px;
}

.step-4 p.maximum {
  justify-content: center;
}

.options-container {
  display: flex;
}

.step4 h3 {
  margin-bottom: 0;
}